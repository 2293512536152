<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data Report'">
        <template v-slot:preview>
          <div class="row justify-content-between" v-if="akses">
            <div class="col-4">
              <b-dropdown id="dropdown-dropright" dropright text="Drop-Right" variant="outline-dark" class="m-2">
                <template #button-content>
                  <span class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-plus"></i>
                  </span>
                  Create
                </template>
                <b-dropdown-item @click="$router.push('/report/create/report')">Report</b-dropdown-item>
                <b-dropdown-item @click="$router.push('/report/create/record')">Record</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="col-1">
              <b-button variant="outline-success" @click="exportToExcel">Export</b-button>
            </div>
          </div>
          
          <b-row>
            <b-col md="12">
              <b-table head-variant="dark" bordered :items="items" :fields="fields" :current-page="currentPage" show-empty
                responsive hover :no-local-sorting="true" @sort-changed="sortTable" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" @row-clicked="info" @filtered="onFiltered" :busy="isBusy">
                <template #thead-top="data">
                  <b-tr>
                    <b-th>
                      <date-picker v-model="search.date" type="date" value-type="YYYY-MM-DD" range
                        input-class="form-control form-control-sm" placeholder="Pilih tanggal dalam range"
                        @change="loadData(false)" @clear="ClearDate"></date-picker>
                    </b-th>
                    <b-th>
                      <b-form-input id="filter-1" size="sm" @input="loadData(false)" v-model="search.no_report"
                        type="search" placeholder="No Report..."></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-select id="filter-2" size="sm" @change="loadData(false)" v-model="search.report_type"
                        :options="report_type"></b-form-select>
                    </b-th>
                    <b-th>
                      <b-form-input id="filter-4" size="sm" @input="loadData(false)" v-model="search.nomor_equipment"
                        type="search" placeholder="Nomor Equipment..."></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-select size="sm" :options="pabrikList" value-field="id_pabrik" text-field="nama_pabrik"
                        v-model="search.pabrik" @change="loadPlant">
                      </b-form-select>
                    </b-th>
                    <b-th>
                      <b-form-select size="sm" :options="plantList" value-field="id_plant" text-field="nama_plant"
                        v-model="search.plant" @change="loadData(false)">
                      </b-form-select>
                    </b-th>
                    <b-th>
                      <b-form-input id="filter-5" size="sm" @input="loadData(false)" v-model="search.nama_part"
                        type="search" placeholder="Nama Part..."></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-select size="sm" :options="critically" v-model="search.critically"
                        @change="loadData(false)"></b-form-select>
                    </b-th>
                    <b-th>
                      <b-form-select size="sm" :options="priority" v-model="search.priority"
                        @change="loadData(false)"></b-form-select>
                    </b-th>
                    <b-th>
                      <b-form-select id="filter-8" size="sm" v-model="search.status" :options="status"
                        @change="loadData(false)"></b-form-select>
                    </b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <!-- <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template> -->

                <template #cell(date)="data">
                  {{ momentDate(data.item.date) }}
                </template>

                <template #cell(report_type)="data">
                  {{
                    data.item.report_type.charAt(0).toUpperCase() + data.item.report_type.slice(1)
                  }}
                </template>

                <template #cell(nomor_equipment)="data">
                  {{ `${data.item.nama_equipment} (${data.item.nomor_equipment})` }}
                </template>

                <template #cell(critically)="data">
                  <span v-if="data.item.critically == '1'">Good</span>
                  <span v-else-if="data.item.critically == '2'">Considerable</span>
                  <span v-else-if="data.item.critically == '3'">Warning</span>
                  <span v-else-if="data.item.critically == '4'">Bad</span>
                  <span v-else-if="data.item.critically == '5'">Breakdown</span>
                  <span v-else>{{ data.item.critically }}</span>
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm">
                    <b-dropdown-item v-if="data.item.verify == '3' &&
                      akses &&
                      UserPabrik == data.item.pabrik_id
                      " @click="print(data.item.id_report)">
                      <span class="svg-icon svg-icon-primary">
                        <i class="flaticon2-printer text-info"></i>&nbsp; Print
                      </span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item @click="download(data.item.id_report)" v-if="akses">
                      <span class="svg-icon svg-icon-primary">
                        <i class="flaticon2-download-2 text-info"></i>&nbsp; Download Word
                      </span>
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="info(data.item, $event.target)">
                      <span class="svg-icon svg-icon-success">
                        <i class="flaticon-eye text-success"></i>&nbsp; Detail
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="data.item.report_type == 'report' && data.item.verify < '3' &&
                      akses &&
                      UserPabrik == data.item.pabrik_id
                      " @click="$router.push('/report/edit/report/' + data.item.id_report)">
                      <span class="svg-icon svg-icon-primary">
                        <i class="flaticon2-pen text-primary"></i>&nbsp; Edit &amp; Approve
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item v-else-if="data.item.report_type == 'record'"
                      @click="$router.push('/report/edit/record/' + data.item.id_report)">
                      <span class="svg-icon svg-icon-primary">
                        <i class="flaticon2-pen text-primary"></i>&nbsp; Edit &amp; Approve
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="akses && UserPabrik == data.item.pabrik_id" @click="toDelete(data)">
                      <span class="svg-icon svg-icon-danger">
                        <i class="flaticon2-trash text-danger"></i>&nbsp; Hapus
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>

              <b-modal id="info-modal" size="xl" :title="`${modalInfo.nomor_equipment} - ${modalInfo.nama_part}`" ok-only
                @hidden="resetInfoModal">
                <b-row>
                  <b-col>
                    <b-form-group label-cols-sm="5" label-cols-lg="5" content-cols-sm content-cols-lg="7" label="Date"
                      label-for="date">
                      <b-form-input id="date" size="sm" v-model="modalInfo.date" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="5" label-cols-lg="5" content-cols-sm content-cols-lg="7"
                      label="No Report" label-for="no_report">
                      <b-form-input id="no_report" size="sm" v-model="modalInfo.no_report" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="No Notifikasi" label-for="no_notifikasi">
                      <b-form-input id="no_notifikasi" size="sm" v-model="modalInfo.no_notifikasi"
                        disabled></b-form-input>
                    </b-form-group>
                    <b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
                      <b-col sm="5" class="text-sm-left">Tujuan Laporan</b-col>
                      <b-col>
                        <ul>
                          <li v-for="(item, index) in JSON.parse(modalInfo.issued_to)" :key="index">
                            {{ JSON.parse(item).label }}
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-form-group label-cols-sm="5" label-cols-lg="5" content-cols-sm content-cols-lg="7"
                      label="Nama Pabrik" label-for="nama_pabrik">
                      <b-form-input id="nama_pabrik" size="sm" v-model="modalInfo.nama_pabrik" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="5" label-cols-lg="5" content-cols-sm content-cols-lg="7"
                      label="Nama Plant" label-for="nama_plant">
                      <b-form-input id="nama_plant" size="sm" v-model="modalInfo.nama_plant" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="5" label-cols-lg="5" content-cols-sm content-cols-lg="7"
                      label="Nama Equipment" label-for="nama_equipment">
                      <b-form-input id="nama_equipment" size="sm" v-model="modalInfo.nama_equipment"
                        disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="5" label-cols-lg="5" content-cols-sm content-cols-lg="7"
                      label="Nama Part" label-for="nama_part">
                      <b-form-input id="nama_part" size="sm" v-model="modalInfo.nama_part" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Method" label-for="method">
                      <ul>
                        <li v-for="(row, index) in modalInfo.method" :key="index">
                          {{ row }}
                        </li>
                      </ul>
                    </b-form-group>
                    <b-form-group label-cols-sm="5" label-cols-lg="5" content-cols-sm content-cols-lg="7"
                      label="Inspector" label-for="inspector">
                      <b-form-input id="inspector" size="sm" v-model="modalInfo.inspector" disabled></b-form-input>
                    </b-form-group>
                    <b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
                      <b-col sm="5" class="text-sm-left">Co-Inspector</b-col>
                      <b-col>
                        <ul>
                          <li v-for="(item, index) in JSON.parse(modalInfo.co_inspector)" :key="index">
                            {{ JSON.parse(item).label }}
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Eksekutor" label-for="eksekutor">
                      <b-form-input id="eksekutor" size="sm" v-model="modalInfo.eksekutor" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="No. WO" label-for="no_wo">
                      <b-form-input id="no_wo" size="sm" v-model="modalInfo.no_wo" disabled></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Hasil Pemeriksaan" label-for="inspection_result">
                      <vue-editor id="inspection_result" :editorToolbar="customToolbar"
                        v-model="modalInfo.inspection_result" :disabled="true"></vue-editor>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'"
                      content-cols-sm content-cols-lg="7" label="Mekanisme Kerusakan" label-for="damage_mechanism">
                      <ul class="mt-3">
                        <li v-for="(dm, indexDm) in modalInfo.damage_mechanism" :key="indexDm">
                          {{ dm }}
                        </li>
                      </ul>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Critically" label-for="critically_modal ">
                      <b-form-input id="critically_modal" size="sm" v-model="modalInfo.critically"
                        disabled></b-form-input>
                    </b-form-group>
                    <b-carousel v-if="modalInfo.image && modalInfo.report_type == 'report'" id="carousel-1"
                      v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="1024"
                      img-height="480" style="text-shadow: 1px 1px 2px #333" @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd">
                      <!-- Text slides with image -->
                      <b-carousel-slide v-for="(img, index) in JSON.parse(modalInfo.image)" :key="index"
                        :img-src="urlStorage + '/images/report/' + img"
                        :text="modalInfo.image_keterangan[index]"></b-carousel-slide>
                    </b-carousel>
                    <span v-else-if="modalInfo.report_type == 'report'">
                      Tidak ada gambar.
                    </span>
                    <b-row class="mb-2" v-if="modalInfo.report_type == 'record'">
                      <b-col sm="5" class="text-sm-left">File</b-col>
                      <b-col>
                        <b-button v-if="JSON.parse(modalInfo.image).length > 0" variant="outline-info" size="sm"
                          :href="urlStorage + '/images/report/' + JSON.parse(modalInfo.image)[0]" target="_blank">
                          <span class="svg-icon svg-icon-sm svg-icon-info">
                            <i class="flaticon-attachment"></i>
                          </span>
                          Lihat File
                        </b-button>
                        <span v-else>Tidak ada file.</span>
                      </b-col>
                    </b-row>
                    <b-form-group v-if="modalInfo.report_type == 'record'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Jenis Record" label-for="jenis_record ">
                      <b-form-input id="jenis_record" size="sm" v-model="modalInfo.jenis_record" disabled></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Rekomendasi " label-for="rekomendasi ">
                      <vue-editor id="rekomendasi " :editorToolbar="customToolbar" v-model="modalInfo.recomendation"
                        :disabled="true"></vue-editor>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Priority" label-for="priority ">
                      <b-form-input id="priority" size="sm" v-model="modalInfo.priority" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Waktu Perbaikan" label-for="onstream_modal ">
                      <b-form-input id="onstream_modal" size="sm" v-model="modalInfo.onstream" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Repair Taken" label-for="repair_taken">
                      <vue-editor id="repair_taken" :editorToolbar="customToolbar" v-model="modalInfo.repair_taken"
                        :disabled="true"></vue-editor>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Status" label-for="status">
                      <b-form-input id="status" size="sm" v-model="modalInfo.status" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="modalInfo.report_type == 'report'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Future Recomendation" label-for="future_recomendation">
                      <vue-editor id="future_recomendation" :editorToolbar="customToolbar"
                        v-model="modalInfo.future_recomendation" :disabled="true"></vue-editor>
                    </b-form-group>
                    <b-row class="mb-2" v-if="modalInfo.report_type == 'report'">
                      <b-col sm="5" class="text-sm-left">File</b-col>
                      <b-col>
                        <b-button v-if="modalInfo.file" variant="outline-info" size="sm"
                          :href="urlStorage + '/file/report/' + modalInfo.file" target="_blank">
                          <span class="svg-icon svg-icon-sm svg-icon-info">
                            <i class="flaticon-attachment"></i>
                          </span>
                          Lihat File
                        </b-button>
                        <span v-else>Tidak ada file.</span>
                        
                      </b-col>
                      
                    </b-row>
                    <b-form-group v-if="modalInfo.report_type == 'record'" label-cols-sm="5" label-cols-lg="5"
                      content-cols-sm content-cols-lg="7" label="Deskripsi" label-for="deskripsi">
                      <vue-editor id="deskripsi" :editorToolbar="customToolbar" v-model="modalInfo.description"
                        :disabled="true"></vue-editor>
                    </b-form-group>
                        <b-form-checkbox v-if="modalInfo.verify >= 2 " v-model="isChecked" disabled>
                          Approval dari kabag
                        </b-form-checkbox>
                        <b-form-checkbox v-if="modalInfo.verify == 3 " v-model="isChecked" disabled>
                          Approval dari Manager
                        </b-form-checkbox>
                        <b-form-checkbox v-if="modalInfo.verify < 2 " v-model="isNotChecked" disabled>
                          Approval dari kabag
                        </b-form-checkbox>
                        <b-form-checkbox v-if="modalInfo.verify < 3 " v-model="isNotChecked" disabled>
                          Approval dari Manager
                        </b-form-checkbox>
                  </b-col>
                  
                </b-row>
              </b-modal>
            </b-col>
            <b-col md="5">

            </b-col>
            <b-col md="5">

            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-pagination v-model="currentPage" @change="paginate" :total-rows="totalRows"
                :per-page="10"></b-pagination>
            </b-col>
            <b-col>
              <p class="float-right mt-3 font-weight-bold">
                Showing
                <span v-if="totalPerPage">{{ ((currentPage - 1) * 10) + 1 }}-{{ ((currentPage - 1) * 10) + 10 }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </p>
            </b-col>
          </b-row>
          <b-modal v-model="modal" okOnly ok-title="Tutup" ok-variant="success">
            <b-row>
              <b-col>
                <span v-html="contentModal"></span>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
    <div id="printMe" style="display: none">
      <div class="container">
        <table class="table table-borderless mt-3 calibri_text" style="margin-bottom: 0">
          <tbody class="border-top">
            <tr>
              <td class="customborder" rowspan="2" style="max-width: 80px">
                <img src="media/logos/logo-pupuk-kujang-cikampek.png" style="width: 100%" alt="" />
              </td>
              <td class="text-center customborder font-judul" style="max-width: 350px">
                <strong>DEPARTEMEN KEANDALAN PABRIK & PENJAMIN KUALITAS<br />
                  STAF INSPEKSI TEKNIK STATIK
                  {{ dataprint.diketahui.pabrik.toUpperCase() }}</strong>
              </td>
              <td class="text-left">Tanggal</td>
              <td class="text-left">:</td>
              <td class="border-right">{{ dataprint.tanggal }}</td>
            </tr>

            <tr>
              <td class="text-center customborder font-judul" style="font-size: 14px; max-width: 350px">
                <strong v-if="dataprint.type == 'Report'">HASIL PEMERIKSAAN DAN REKOMENDASI</strong>
                <strong v-if="dataprint.type == 'Record'">{{ dataprint.jenis_record.toUpperCase() }}</strong>
              </td>
              <td class="border-bottom">Halaman</td>
              <td class="border-bottom">:</td>
              <td class="border-bottom border-right">1 dari 1</td>
            </tr>
          </tbody>
        </table>
        <table id="second-table" class="table table-borderless" style="margin: 0">
          <tbody>
            <tr class="border-left">
              <td style="width: 120px; font-size: 12px" class="pl-2">
                No Laporan
              </td>
              <td style="width: 2px; font-size: 12px">:</td>
              <td style="font-size: 12px">{{ dataprint.no_report }}</td>
              <td class="no-middle border-bottom border-right" style="width: 500px; font-size: 12px" rowspan="4">
                <div class="row">
                  <div class="col-5" v-if="dataprint.type == 'Report'">Ditujukan Kepada :</div>
                  <div class="col-7">
                    <ol>
                      <li v-for="(item, index) in dataprint.issued_to" :key="index">
                        {{ item }}
                      </li>
                    </ol>
                  </div>
                </div>
              </td>
              <!-- <td class="no-middle border-bottom border-right" style="200px" rowspan="4">
                        
                    </td> -->
            </tr>
            <tr class="border-left">
              <td class="pl-2" style="width: 100px; font-size: 12px">
                No Peralatan
              </td>
              <td style="width: 10px; font-size: 12px">:</td>
              <td style="width: 580px; font-size: 12px">
                {{ dataprint.nomor_equipment }}
              </td>
            </tr>
            <tr class="border-left">
              <td class="pl-2">No Notifikasi</td>
              <td>:</td>
              <td></td>
            </tr>
            <tr class="border-left border-bottom">
              <td class="pl-2" style="width: 100px">Unit</td>
              <td>:</td>
              <td>{{ dataprint.unit }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Table form Report -->
        <table v-if="dataprint.type == 'Report'" id="third-table" class="table table-borderless calibri_text" style="margin: 0">
          <thead class="text-center border-left border-right">
            <tr>
              <th style="font-size: 12px !important">NO</th>
              <th class="border-left" style="width: 150px; font-size: 12px !important">
                OBYEK
              </th>
              <th class="border-left" style="font-size: 12px !important">
                METODE
              </th>
              <th class="border-left" style="font-size: 12px !important">
                HASIL PEMERIKSAAN
              </th>
              <th class="border-left" style="font-size: 12px !important">
                REKOMENDASI
              </th>
              <th class="border-left" style="font-size: 12px !important">
                PELAKSANAAN PERBAIKAN
              </th>
              <th class="border-left" style="font-size: 12px !important">
                REKOMENDASI YANG AKAN DATANG
              </th>
            </tr>
          </thead>
          <tbody class="customborder">
            <tr v-for="(item, index) in dataprint.items" :key="index">
              <td class="border-left align-top text-center">
                <p v-html="index + 1" style="font-size: 12px !important"></p>
              </td>
              <td class="border-left align-top text-center">
                <p v-html="item.part" style="font-size: 12px !important"></p>
              </td>
              <td class="border-left align-top text-center">
                <span style="font-size: 12px !important">
                  <ul>
                    <li v-for="(row, index) in item.method" :key="index">
                      {{ row }}
                    </li>
                  </ul>
                </span>
              </td>
              <td class="border-left align-top text-justify">
                <div v-html="item.inspection_result" style="font-size: 12px !important"></div>
              </td>
              <td class="border-left align-top text-justify">
                <div class="printstyle" v-html="item.recomendation" style="font-size: 12px !important"></div>
              </td>
              <td class="border-left align-top text-justify">
                <div v-html="item.repair_taken" style="font-size: 12px !important"></div>
              </td>
              <td class="border-left align-top text-justify">
                <div v-html="item.future_recomendation" style="font-size: 12px !important"></div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Table Form Record -->
        <table v-if="dataprint.type == 'Record'" id="third-table" class="table table-borderless calibri_text" style="margin: 0">
          <thead class="text-center border-left border-right">
            <tr>
              <th style="font-size: 12px !important">NO</th>
              <th class="border-left" style="width: 150px; font-size: 12px !important">
                OBYEK
              </th>
              <th class="border-left" style="font-size: 12px !important">
                EQUIPMENT
              </th>
              <th class="border-left" style="font-size: 12px !important">
                DESKRIPSI
              </th>
              
            </tr>
          </thead>
          <tbody class="customborder">
            <tr v-for="(item, index) in dataprint.items" :key="index">
              <td class="border-left align-top text-center">
                <p v-html="index + 1" style="font-size: 12px !important"></p>
              </td>
              <td class="border-left align-top text-center">
                <p v-html="item.part" style="font-size: 12px !important"></p>
              </td>
              
              <td class="border-left align-top text-justify">
                <div v-html="item.equipment" style="font-size: 12px !important"></div>
              </td>
              <td class="border-left align-top text-justify">
                <div v-html="item.description" style="font-size: 12px !important"></div>
              </td>
              
            </tr>
          </tbody>
        </table>
        <table id="fifth-table" class="table table-borderless calibri_text" style="margin: 0">
          <tbody class="border-right border-left border-bottom">
            <tr>
              <td :colspan="dataprint.co_inspector.length + 1" class="text-center no-middle" style="height: 60px">
                Inspektor
              </td>
              <td class="no-middle">
                Diketahui/Disahkan oleh <br />
                Manager {{ dataprint.diketahui.pabrik }}
              </td>
            </tr>
            <tr>
              <!--<td class="text-left" v-if="dataprint.co_inspector.length"></td>
              <td class="text-center" v-else>ttd</td>!-->
              <td class="text-center">ttd</td>
              <td class="align-top text-center" v-for="(item, index) in dataprint.co_inspector" :key="index">
                ttd
              </td>
              <td class="text-left">ttd</td>
            </tr>
            <tr>
              <!--<td class="text-left" v-if="dataprint.co_inspector.length"></td>
              <td class="text-center" v-else>
                {{ dataprint.diketahui.inspector_name }}
              </td>!-->
              <td class="text-center">
                {{ dataprint.diketahui.inspector_name }}
              </td>
              <td class="align-top text-center" v-for="(item, index) in dataprint.co_inspector" :key="index">
                {{ item }}
              </td>
              <td class="text-left">{{ dataprint.diketahui.nama }}</td>
            </tr>
          </tbody>
        </table>
        <table id="fourth-table" class="table table-borderless calibri_text" style="margin: 0">
          <tbody class="border-right border-left border-bottom">
            <tr>
              <td>
                <div style="float:left; margin-right: 5px;" v-for="(item, index) in dataprint.img" :key="index">
                  <b-img style="max-height: 200px" thumbnail fluid :src="urlStorage + '/images/report/' + item"></b-img>
                  <br />
                  <p>
                    {{ dataprint.image_keterangan[index] }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <b-row>
          <b-col>&nbsp;</b-col>
          <b-col><vue-qr :text="urlqr" :size="200"></vue-qr></b-col>
          <b-col>&nbsp;</b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import Vue from 'vue'
import { VueEditor } from 'vue2-editor'
import * as moment from 'moment'
import 'moment/locale/id'
import VueQr from 'vue-qr'
import axios from 'axios'
import JwtService from '@/core/services/jwt.service'



export default {
  data() {
    return {
      isChecked: true,
      isNotChecked:false,
      urlqr: '',
      critically: [
        { value: null, text: 'Semua' },
        { value: '1', text: 'Good' },
        { value: '2', text: 'Considerable' },
        { value: '3', text: 'Warning' },
        { value: '4', text: 'Bad' },
        { value: '5', text: 'Breakdown' },
      ],
      onstream: [
        { value: null, text: 'Semua' },
        { value: 'Onstream', text: 'Onstream' },
        { value: 'Shutdown', text: 'Shutdown' },
      ],
      priority: [
        { value: null, text: 'Semua' },
        { value: 'Low', text: 'Low' },
        { value: 'Medium', text: 'Medium' },
        { value: 'High', text: 'High' },
        { value: 'Turn Arround', text: 'Turn Arround' },
      ],
      status: [
        { value: '', text: 'Semua' },
        { value: 'Belum dilakukan', text: 'Belum dilakukan' },
        { value: 'Sudah dilakukan', text: 'Sudah dilakukan' },

        {
          value: 'Dilakukan tidak sesuai rekomendasi',
          text: 'Dilakukan tidak sesuai rekomendasi',
        },
      ],
      pabrikList: [],
      plantList: [],
      customToolbar: [[]],
      UserPabrik: null,
      items: [],
      sortBy: 'date',
      sortDesc: true,
      sort_label: 'DESC',
      fields: [
        { key: 'date', label: 'Tanggal', sortable: true },
        { key: 'no_report', label: 'No Laporan', sortable: true },
        { key: 'report_type', label: 'Tipe', sortable: true },
        { key: 'nomor_equipment', label: 'Nomor Equipment', sortable: true },
        { key: 'nama_pabrik', label: 'Pabrik', sortable: true },
        { key: 'nama_plant', label: 'Plant', sortable: true },
        { key: 'nama_part', label: 'Part/Objek', sortable: true },
        { key: 'critically', label: 'Critically', sortable: true },
        { key: 'priority', label: 'Priority', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],

      modalInfo: {
        nama_equipment: null,
        nomor_equipment: null,
        nama_pabrik: null,
        nama_plant: null,
        nama_part: null,
        date: null,
        no_report: null,
        method: [],
        damage_mechanism: [],
        jenis_record: null,
        issued_to: null,
        image: null,
        image_keterangan: null,
        file: null,
        inspector: null,
        co_inspector: null,
        type: null,
        description: null,
        critically: null,
        onstream: null,
        priority: null,
        repair_taken: null,
        status: null,
        inspection_result: null,
        recomendation: null,
        future_recomendation: null,
        report_type: null,
        eksekutor: null,
        no_wo: null,
      },

      search: {
        date: ['', ''],
        no_report: '',
        report_type: 'all',
        nomor_equipment: '',
        nama_pabrik: '',
        nama_plant: '',
        nama_part: '',
        critically: '',
        priority: '',
        status: '',
        pabrik: '',
        plant: '',
      },
      report_type: [
        { value: 'all', text: 'All' },
        { value: 'report', text: 'Report' },
        { value: 'record', text: 'Record' },
      ],
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      show: true,
      isBusy: false,
      dialog: false,

      modal: false,
      contentModal: '',
      akses: false,

      urlStorage: '',
      slide: 0,

      dataprint: {
        img: [],
        image_keterangan: [],
        no_report: null,
        nomor_equipment: null,
        unit: null,
        issued_to: [],
        items: [],
        co_inspector: [],
        diketahui: {
          pabrik: 'Pabrik',
          nama: null,
          inspector_name: null,
        },
      },
    }
  },
  components: {
    KTCodePreview,
    VueEditor,
    VueQr,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Report', route: '' }, { title: 'Create' }])
  },
  created() {
    
    this.loadData(true)
    this.loadPabrik()
    this.urlStorage = ApiService.urlStorage()
    console.log(this.urlStorage + '/images/report/');
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    modalShow(content) {
      this.contentModal = content
      this.modal = true
    },
    moment: function (date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD LTS').format('dddd, LL')
    },
    momentDate: function (date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD LTS').format('DD MMM YYYY')
    },
    info(item, button) {
      // console.log(item)
      this.modalInfo.nama_pabrik = item.nama_pabrik
      this.modalInfo.nama_equipment = item.nama_equipment
      this.modalInfo.nomor_equipment = item.nomor_equipment
      this.modalInfo.nama_pabrik = item.nama_pabrik
      this.modalInfo.nama_plant = item.nama_plant
      this.modalInfo.nama_part = item.nama_part
      this.modalInfo.date = item.date
      this.modalInfo.no_report = item.no_report
      this.modalInfo.no_notifikasi = item.no_notifikasi
      
      // for (let i = 0; i < item.method.length; i++) {
      //   const element = array[i];
        
      // }
      this.modalInfo.method = item.method
      // ApiService.get('/user/users/edit/' + item.inspector).then(({ data }) => {
      //   this.modalInfo.inspector = data.data.name
      // })
      this.modalInfo.inspector = item.inspector
      if (item.damage_mechanism_id == null) {
        this.modalInfo.damage_mechanism = item.damage_mechanism_id
      }else{
        let str = item.damage_mechanism_id
        let arr_dmg_mechanism = str.split(",") 
        // console.log(item)
        var damage_mechanism = []
        for(let i=0;i < arr_dmg_mechanism.length;i++){
          
          ApiService.get('/master/inspectionplant/getDamageMechanism/' + arr_dmg_mechanism[i]).then(({ data }) => {
          damage_mechanism.push(data.data.nama_damage_mechanism)
          })

        }
        //output yang diharapkan dalam bentuk array
        
        // this.modalInfo.damage_mechanism = JSON.parse(item.damage_mechanism)
        this.modalInfo.damage_mechanism = damage_mechanism
      }
      
      this.modalInfo.jenis_record = item.jenis_record
      if (item.issued_to == '' || item.issued_to == null) {
        this.modalInfo.issued_to = {}
      } else {
        this.modalInfo.issued_to = item.issued_to
      }
      this.modalInfo.image = item.image
      if (item.report_type == 'report') {
        if (item.image_keterangan == null) {
          this.modalInfo.image_keterangan = ['']
        } else {
          this.modalInfo.image_keterangan = JSON.parse(item.image_keterangan)
        }
      } else {
        this.modalInfo.image_keterangan = ['']
      }

      this.modalInfo.file = item.file
      // this.modalInfo.inspector = item.inspector

      if (item.co_inspector == '' || item.co_inspector == null) {
        this.modalInfo.co_inspector = {}
      } else {
        this.modalInfo.co_inspector = item.co_inspector
      }
      this.modalInfo.type = item.type
      this.modalInfo.description = item.description

      let critically = item.critically
      if (critically == '1') {
        critically = 'Good'
      } else if (critically == '2') {
        critically = 'Considerable'
      } else if (critically == '3') {
        critically = 'Warning'
      } else if (critically == '4') {
        critically = 'Bad'
      } else if (critically == '5') {
        critically = 'Breakdown'
      }

      this.modalInfo.critically = critically
      this.modalInfo.onstream = item.onstream
      this.modalInfo.priority = item.priority
      this.modalInfo.repair_taken = item.repair_taken
      this.modalInfo.status = item.status
      this.modalInfo.inspection_result = item.inspection_result
      this.modalInfo.recomendation = item.recomendation
      this.modalInfo.future_recomendation = item.future_recomendation
      this.modalInfo.eksekutor = item.eksekutor
      this.modalInfo.no_wo = item.no_wo
      this.modalInfo.report_type = item.report_type
      this.modalInfo.verify = item.verify
      this.$root.$emit('bv::show::modal', 'info-modal', button)
      
    },
    resetInfoModal() {
      this.modalInfo.nama_equipment = null
      this.modalInfo.nomor_equipment = null
      this.modalInfo.nama_pabrik = null
      this.modalInfo.nama_plant = null
      this.modalInfo.nama_part = null
      this.modalInfo.date = null
      this.modalInfo.no_report = null
      this.modalInfo.no_notifikasi = null
      if (this.modalInfo.method == null) {
        this.modalInfo.method = null  
      }else{
        this.modalInfo.method.splice(0, this.modalInfo.method.length)
      }
      if(this.modalInfo.damage_mechanism == null){
        this.modalInfo.damage_mechanism == null
      }else{
        this.modalInfo.damage_mechanism.splice(0, this.modalInfo.damage_mechanism.length)
      }
      this.modalInfo.jenis_record = null
      this.modalInfo.issued_to = null
      this.modalInfo.image = null
      this.modalInfo.image_keterangan = null
      this.modalInfo.file = null
      this.modalInfo.inspector = null
      this.modalInfo.co_inspector = null
      this.modalInfo.type = null
      this.modalInfo.description = null
      this.modalInfo.critically = null
      this.modalInfo.onstream = null
      this.modalInfo.priority = null
      this.modalInfo.repair_taken = null
      this.modalInfo.status = null
      this.modalInfo.inspection_result = null
      this.modalInfo.recomendation = null
      this.modalInfo.eksekutor = null
      this.modalInfo.no_wo = null
      this.modalInfo.report_type = null
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    download(idreport) {
      location.href = Vue.axios.defaults.baseURL + '/word/' + idreport
    },
    async print(id_report) {
      // Pass the element id here
      const self = this
      await ApiService.get('/report/create/edit/' + id_report)
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.urlqr = 'http://syspek.pupuk-kujang.co.id/#/report/detail_report/' + id_report
            // const report = data.data
            // console.log(report.length)
            // const report_type = data.data[0].report_type
            if (data.report_type == "Report") {
              self.dataprint.type = data.report_type
              self.dataprint.tanggal = self.momentDate(data.data[0].date)
              self.dataprint.no_report = data.data[0].no_report
              self.dataprint.nomor_equipment = data.data[0].nomor_equipment
              self.dataprint.unit = data.data[0].nama_plant
              self.dataprint.diketahui.pabrik = data.data[0].nama_pabrik
              self.dataprint.diketahui.nama = data.data[0].group_leader
              self.dataprint.diketahui.inspector_name = data.data[0].inspector_name

              const issued_to = JSON.parse(data.data[0].issued_to)
              self.dataprint.issued_to.splice(0, self.dataprint.issued_to.length)
              for (let i = 0; i < issued_to.length; i++) {
                const o_issued_to = JSON.parse(issued_to[i])
                self.dataprint.issued_to.push(o_issued_to.label)
              }

              if (data.data[0].co_inspector != 'null') {
              const co_inspector = JSON.parse(data.data[0].co_inspector)
              self.dataprint.co_inspector.splice(0, self.dataprint.co_inspector.length)
                  for (let i = 0; i < co_inspector.length; i++) {
                    const o_co_inspector = JSON.parse(co_inspector[i])
                    self.dataprint.co_inspector.push(o_co_inspector.label)
                    }
                  }

              var item = data.data
              self.dataprint.img = []
              self.dataprint.image_keterangan = []
              self.dataprint.items.splice(0, self.dataprint.items.length)
              for (let i = 0; i < item.length; i++) {
                var ket = ''
                if (item[i].image_keterangan == null) {
                  ket = ''
                } else {
                  ket = JSON.parse(item[i].image_keterangan)
                  const p = JSON.parse(item[i].image_keterangan)
                  for (var j = 0; j < p.length; j++) {
                    self.dataprint.image_keterangan.push(p[j])
                  }
                }
                const x = JSON.parse(item[i].image)
                for (var j = 0; j < x.length; j++) {
                  self.dataprint.img.push(x[j])
                }

                self.dataprint.items.push({
                  part: item[i].nama_part,
                  recomendation: item[i].recomendation,
                  repair_taken: item[i].repair_taken,
                  inspection_result: item[i].inspection_result,
                  future_recomendation: item[i].future_recomendation,
                  method: item[i].method,
                  damage_mechanism: item[i].damage_mechanism,
                  img: item[i].image,
                  image_keterangan: ket,
                })
              }
            }else{
              self.dataprint.type = data.report_type
              self.dataprint.tanggal = self.momentDate(data.data.date)
              self.dataprint.no_report = data.data.no_report
              self.dataprint.nomor_equipment = data.data.nomor_equipment
              self.dataprint.unit = data.data.nama_plant
              self.dataprint.diketahui.pabrik = data.data.nama_pabrik
              self.dataprint.diketahui.nama = data.data.group_leader
              self.dataprint.diketahui.inspector_name = data.data.inspector_name
              self.dataprint.jenis_record = data.data.jenis_record

              const items = data.data
              self.dataprint.items.push({
                  part: items.nama_part,
                  equipment: items.nama_equipment,
                  description: items.description
                })
            }

            setTimeout(() => {
              self.$htmlToPaper('printMe')
            }, 100)
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    ClearDate() {
      this.search.date[0] = ''
      this.search.date[1] = ''
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadPlant() {
      var self = this
      self.loadData(false)
      self.plantList = { id_plant: null, nama_plant: 'Semua' }
      ApiService.get('/master/plant/get?pabrik=' + self.search.pabrik).then(({ data }) => {
        self.plantList = [self.plantList, ...data.data]
      })
    },
    async loadPabrik() {
      var self = this
      self.pabrikList = { id_pabrik: null, nama_pabrik: 'Semua' }
      await ApiService.get('/master/pabrik/get').then(({ data }) => {
        self.pabrikList = [self.pabrikList, ...data.data]
      })
    },
    async loadData(set) {
      if (set) {
        Object.entries(this.$route.query).forEach(([key, value]) => {
          if (value && key !== 'date') {
            this.search[key] = value
          }

          if (key === 'date_start' && value) {
            this.search.date[0] = value
          } else if (key === 'date_end' && value) {
            this.search.date[1] = value
          }
        })

        if (this.search.pabrik) {
          this.loadPlant()
        }
      }

      //this.$router.push({ query: {} })
      await this.$nextTick()

      let query = {}
      Object.entries(this.search).forEach(([key, value]) => {
        if (value && key !== 'date') {
          query[key] = value
        } else if (!value && key !== 'date') {
          delete query[key]
        }

        if (key === 'date' && value[0]) {
          query.date_start = value[0]
          query.date_end = value[1]
        } else if (key === 'date' && !value[0]) {
          delete query.date_start
          delete query.date_end
        }
      })

      //this.$router.push({ query })

      let param = ''
      Object.entries(query).forEach(([key, value]) => {
        param += `&${key}=${value}`
      })

      var self = this
      self.isBusy = true
      await this.timeout(100)
      ApiService.get(
        '/report/create?page=' +
        this.currentPage +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label +
        param
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.akses = data.akses
            self.UserPabrik = data.pabrik
            self.items = data.data.data
            for (var i = 0; i < self.items.length; i++) {
              if (self.items[i]['verify'] == 2) {
                self.items[i]['_rowVariant'] = 'warning'
              }
              if (self.items[i]['verify'] == 3) {
                self.items[i]['_rowVariant'] = 'success'
              }

              if (self.items[i]['status'] == 'null') self.items[i]['status'] = ''
              if (self.items[i]['priority'] == 'null') self.items[i]['priority'] = ''
              if (self.items[i]['critically'] == 'null') self.items[i]['critically'] = ''
            }
            self.totalRows = data.data.total
            self.totalPerPage = data.data.data.length
            self.isBusy = false
            // console.log(data)
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    async exportToExcel() {
      // if (set) {
      //   Object.entries(this.$route.query).forEach(([key, value]) => {
      //     if (value && key !== 'date') {
      //       this.search[key] = value
      //     }

      //     if (key === 'date_start' && value) {
      //       this.search.date[0] = value
      //     } else if (key === 'date_end' && value) {
      //       this.search.date[1] = value
      //     }
      //   })

      //   if (this.search.pabrik) {
      //     this.loadPlant()
      //   }
      // }

      // await this.$nextTick()

      let query = {}
      Object.entries(this.search).forEach(([key, value]) => {
        if (value && key !== 'date') {
          query[key] = value
        } else if (!value && key !== 'date') {
          delete query[key]
        }

        if (key === 'date' && value[0]) {
          query.date_start = value[0]
          query.date_end = value[1]
        } else if (key === 'date' && !value[0]) {
          delete query.date_start
          delete query.date_end
        }
      })

      let param = ''
      Object.entries(query).forEach(([key, value]) => {
        param += `&${key}=${value}`
      })

      let self = this
      self.isBusy = true

      axios({
        method: 'post',
        responseType: 'arraybuffer',
        url: '/report/create/export?sortBy=' + this.sortBy + '&sortDesc=' + this.sort_label + param,
        headers: {
          Accept: 'multipart/form-data',
          Authorization: 'Bearer ' + JwtService.getToken(),
        },
      }).then(function (response) {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `Report_${new Date().toLocaleDateString()}.xlsx`)
        document.body.appendChild(fileLink)
        fileLink.click()
        self.isBusy = false
      })
    },
    toDelete(row) {
      var id = row.item.id_report
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/report/create/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData(false)
                interval1 = setInterval(function () {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
<style scoped>
.mx-datepicker-range {
  width: 200px;
}

.modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.calibri_text {
  font-family: 'Calibri, sans-serif';
}

.printstyle p {
  font-size: 12 !important;
}
</style>
